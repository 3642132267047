import { useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';

const Timer = ({ expiryDate, style }) => {
    const isMobile = useMediaQuery('(max-width:800px)');

    const calculateTimeLeft = () => {
        const difference = expiryDate.getTime() - new Date().getTime();

        let timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const formatTime = (time) => {
        return time < 10 ? `0${time}` : `${time}`;
    };
    

    return (
        <div className={`flex font-bold ${style} overflow-auto`}>
            <div>
                <p className='text-center text-[16px] lg:text-[60px]'>
                    {formatTime(timeLeft.days)}
                </p>
                <p className={isMobile ? 'text-[12px] text-center tracking-wider' : 'text-center tracking-wider'}>
                    DAYS
                </p>
            </div>

            <div className='mx-2 text-[20px] lg:text-[60px]'>
                :
            </div>

            <div>
                <p className='text-center text-[16px] lg:text-[60px]'>
                    {formatTime(timeLeft.hours)}
                </p>
                <p className={isMobile ? 'text-[12px] text-center tracking-wider' : 'text-center tracking-wider'}>
                    HOURS
                </p>
            </div>

            <div className='mx-2 text-[20px] lg:text-[60px]'>
                :
            </div>

            <div>
                <p className='text-center text-[16px] lg:text-[60px]'>
                    {formatTime(timeLeft.minutes)}
                </p>
                <p className={isMobile ? 'text-[12px] text-center tracking-wider' : 'text-center tracking-wider'}>
                    MINUTES
                </p>
            </div>

            <div className='mx-2 text-[20px] lg:text-[60px]'>
                :
            </div>

            <div>
                <p className='text-center text-[16px] lg:text-[60px]'>
                    {formatTime(timeLeft.seconds)}
                </p>
                <p className={isMobile ? 'text-[12px] text-center tracking-wider' : 'text-center tracking-wider'}>
                    SECONDS
                </p>
            </div>
            <p>
                {/* {formatTime(timeLeft.days)}::: */}
            </p>
        </div>
    );
};

export default Timer;