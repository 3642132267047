import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Paper, List, ListItem, ListItemText, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { fetchMasterQuestById, updateQuest, deleteQuest, createQuest, linkMasterQuest, deleteMasterQuest, editMasterQuest } from '../api/auth';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const QuestDetails = ({ quest, onBack, token, onQuestDelete }) => {
    const [subQuests, setSubQuests] = useState([]);
    const [editSubQuest, setEditSubQuest] = useState(null); // Track the sub-quest being edited
    const [editDialogOpen, setEditDialogOpen] = useState(false); // Control the edit dialog visibility
    const [newTaskDialogOpen, setNewTaskDialogOpen] = useState(false); // Control the new task dialog visibility
    const [newTask, setNewTask] = useState({
        tag_line: '',
        description: '',
        points: 0,
        file_url: [],
        total_uploads: 1,
        is_auto: false,
        task_type: '' // Added type field for task type selection
    });

    const [isEditingMasterQuest, setIsEditingMasterQuest] = useState(false); // Control the edit master quest dialog visibility
    const [editMasterQuestData, setEditMasterQuestData] = useState({
        tag_line: quest.tag_line,
        description: quest.description,
        starts_at: quest.starts_at,
        ends_at: quest.ends_at,
    });

    useEffect(() => {
        const fetchQuestDetails = async () => {
            try {
                const masterQuestDetails = await fetchMasterQuestById(token, quest._id);
                setSubQuests(masterQuestDetails.quest_id || []);
            } catch (error) {
                console.error('Error fetching master quest details:', error);
            }
        };

        fetchQuestDetails();
    }, [quest._id, token]);

    const handleEditClick = (subQuest) => {
        setEditSubQuest(subQuest);
        setEditDialogOpen(true);
    };

    const handleDeleteClick = async (subQuestId) => {
        try {
            await deleteQuest(token, subQuestId);
            setSubQuests(subQuests.filter(sq => sq._id !== subQuestId)); // Remove the deleted sub-quest from the state
        } catch (error) {
            console.error('Error deleting sub-quest:', error);
        }
    };

    const handleEditSubmit = async () => {
        try {
            const payload = {
                tag_line: editSubQuest.tag_line,
                description: editSubQuest.description,
                points: editSubQuest.points,
                file_url: editSubQuest.file_url || [],
                total_uploads: editSubQuest.total_uploads,
                is_auto: editSubQuest.is_auto,
                starts_at: quest.starts_at, // Use master quest start date
                ends_at: quest.ends_at, // Use master quest end date
            };

            await updateQuest(token, editSubQuest._id, payload);

            setSubQuests(subQuests.map(sq => (sq._id === editSubQuest._id ? editSubQuest : sq))); // Update the sub-quest in the state
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Error updating sub-quest:', error);
        }
    };

    const handleEditChange = (field, value) => {
        setEditSubQuest(prev => ({ ...prev, [field]: value }));
    };

    const handleNewTaskChange = (field, value) => {
        setNewTask(prev => ({ ...prev, [field]: value }));
    };

    const handleCreateNewTask = async () => {
        try {
            // Step 1: Create the new sub-quest
            const newSubQuest = await createQuest(token, {
                ...newTask,
                starts_at: quest.starts_at, // Use master quest start date
                ends_at: quest.ends_at, // Use master quest end date
            });

            // Step 2: Link the new sub-quest to the master quest
            await linkMasterQuest(token, newSubQuest._id, quest._id);

            // Step 3: Update the local state with the new sub-quest
            setSubQuests([...subQuests, newSubQuest]);

            // Close the dialog and reset the new task form
            setNewTaskDialogOpen(false);
            setNewTask({
                tag_line: '',
                description: '',
                points: 0,
                file_url: [],
                total_uploads: 1,
                is_auto: false,
                task_type: ''
            });
        } catch (error) {
            console.error('Error creating new sub-quest:', error);
        }
    };

    const handleDeleteMasterQuest = async () => {
        try {
            await deleteMasterQuest(token, quest._id);
            onQuestDelete(quest._id);
        } catch (error) {
            console.error('Error deleting master quest:', error);
        }
    };

    const handleMasterQuestEditSubmit = async () => {
        try {
            const payload = {
                tag_line: editMasterQuestData.tag_line,
                description: editMasterQuestData.description,
                starts_at: editMasterQuestData.starts_at,
                ends_at: editMasterQuestData.ends_at,
            };

            await editMasterQuest(token, quest._id, payload);

            // Update the quest state with the new data
            quest.tag_line = editMasterQuestData.tag_line;
            quest.description = editMasterQuestData.description;
            quest.starts_at = editMasterQuestData.starts_at;
            quest.ends_at = editMasterQuestData.ends_at;
            setIsEditingMasterQuest(false);
        } catch (error) {
            console.error('Error updating master quest:', error);
        }
    };

    const handleEditMasterQuestChange = (field, value) => {
        setEditMasterQuestData(prev => ({ ...prev, [field]: value }));
    };

    return (
        <div className='bg-gray-200 p-8 rounded-md'>
            <h2 className='text-2xl font-bold mb-5'>MASTER QUEST DETAILS</h2>
            <TextField
                label="Quest Name"
                fullWidth
                value={quest.tag_line}
                disabled
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="Quest Description"
                fullWidth
                multiline
                rows={4}
                value={quest.description}
                disabled
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="Start Date"
                fullWidth
                value={new Date(quest.starts_at).toLocaleString()}
                disabled
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="End Date"
                fullWidth
                value={new Date(quest.ends_at).toLocaleString()}
                disabled
                sx={{ marginBottom: '20px' }}
            />
            <Typography variant="h6" gutterBottom>
                Sub-Quests
            </Typography>
            {subQuests.length > 0 ? (
                <List component={Paper}>
                    {subQuests.map((subQuest, index) => (
                        <ListItem key={index} secondaryAction={
                            <>
                                <IconButton edge="end" onClick={() => handleEditClick(subQuest)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" onClick={() => handleDeleteClick(subQuest._id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }>
                            <ListItemText
                                primary={`Sub-Quest: ${subQuest.tag_line}`}
                                secondary={
                                    <>
                                        <Typography variant="body2">
                                            Description: {subQuest.description}
                                        </Typography>
                                        <Typography variant="body2">
                                            Points: {subQuest.points}
                                        </Typography>
                                        <Typography variant="body2">
                                            Total Uploads: {subQuest.total_uploads}
                                        </Typography>
                                        <Typography variant="body2">
                                            Starts At: {new Date(quest.starts_at).toLocaleString()}
                                        </Typography>
                                        <Typography variant="body2">
                                            Ends At: {new Date(quest.ends_at).toLocaleString()}
                                        </Typography>
                                        <Typography variant="body2">
                                            File URLs: {subQuest.file_url.length > 0 ? subQuest.file_url.join(', ') : 'None'}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography>No sub-quests available</Typography>
            )}
            <Button
                variant="contained"
                sx={{ backgroundColor: '#7BB92F', color: 'white', marginTop: '20px' }}
                onClick={() => setNewTaskDialogOpen(true)}
            >
                + New Task
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#7BB92F', color: 'white', marginTop: '20px', marginLeft: '10px' }}
                onClick={onBack}
            >
                Back to Quests
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#FF4D4F', color: 'white', marginTop: '20px', marginLeft: '10px' }}
                onClick={handleDeleteMasterQuest}
            >
                Delete Master Quest
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#FFA726', color: 'white', marginTop: '20px', marginLeft: '10px' }}
                onClick={() => setIsEditingMasterQuest(true)}
            >
                Edit Master Quest
            </Button>

            {/* Edit Sub-Quest Dialog */}
            {editSubQuest && (
                <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                    <DialogTitle>Edit Sub-Quest</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Sub-Quest Name"
                            fullWidth
                            value={editSubQuest.tag_line}
                            onChange={(e) => handleEditChange('tag_line', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={4}
                            value={editSubQuest.description}
                            onChange={(e) => handleEditChange('description', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                            <InputLabel id="task-type-label">Task Type</InputLabel>
                            <Select
                                labelId="task-type-label"
                                value={editSubQuest.type || ''}
                                label="Task Type"
                                onChange={(e) => handleEditChange('type', e.target.value)}
                            >
                                <MenuItem value={editSubQuest.task_type = 'File Upload'}>File Upload</MenuItem>
                                <MenuItem value={editSubQuest.task_type = 'Provide a Link'}>Provide a Link</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Points"
                            fullWidth
                            type="number"
                            value={editSubQuest.points}
                            onChange={(e) => handleEditChange('points', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="Total Uploads"
                            fullWidth
                            type="number"
                            value={editSubQuest.total_uploads}
                            onChange={(e) => handleEditChange('total_uploads', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="File URLs"
                            fullWidth
                            value={editSubQuest.file_url.join(', ')}
                            onChange={(e) => handleEditChange('file_url', e.target.value.split(',').map(url => url.trim()))}
                            sx={{ marginBottom: '20px' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setEditDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleEditSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Edit Master Quest Dialog */}
            {isEditingMasterQuest && (
                <Dialog open={isEditingMasterQuest} onClose={() => setIsEditingMasterQuest(false)}>
                    <DialogTitle>Edit Master Quest</DialogTitle>
                    <DialogContent>
                        <TextField
                            label="Quest Name"
                            fullWidth
                            value={editMasterQuestData.tag_line}
                            onChange={(e) => handleEditMasterQuestChange('tag_line', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="Quest Description"
                            fullWidth
                            multiline
                            rows={4}
                            value={editMasterQuestData.description}
                            onChange={(e) => handleEditMasterQuestChange('description', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="Start Date"
                            type="datetime-local"
                            fullWidth
                            value={editMasterQuestData.starts_at.slice(0, 16)}
                            onChange={(e) => handleEditMasterQuestChange('starts_at', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                        <TextField
                            label="End Date"
                            type="datetime-local"
                            fullWidth
                            value={editMasterQuestData.ends_at.slice(0, 16)}
                            onChange={(e) => handleEditMasterQuestChange('ends_at', e.target.value)}
                            sx={{ marginBottom: '20px' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsEditingMasterQuest(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleMasterQuestEditSubmit} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* New Task Dialog */}
            <Dialog open={newTaskDialogOpen} onClose={() => setNewTaskDialogOpen(false)}>
                <DialogTitle>Create New Task</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Task Name"
                        fullWidth
                        onChange={(e) => handleNewTaskChange('tag_line', e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        value={newTask.description}
                        onChange={(e) => handleNewTaskChange('description', e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                        <InputLabel id="task-type-label">Task Type</InputLabel>
                        <Select
                            labelId="task-type-label"
                            value={newTask.task_type || ''}
                            label="Task Type"
                            onChange={(e) => handleNewTaskChange('task_type', e.target.value)}
                        >
                            <MenuItem value={'File Upload'}>File Upload</MenuItem>
                            <MenuItem value={'Provide a Link'}>Provide a Link</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Points"
                        fullWidth
                        type="number"
                        value={newTask.points}
                        onChange={(e) => handleNewTaskChange('points', e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="Total Uploads"
                        fullWidth
                        type="number"
                        value={newTask.total_uploads}
                        onChange={(e) => handleNewTaskChange('total_uploads', e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                    <TextField
                        label="File URLs"
                        fullWidth
                        value={newTask.file_url.join(', ')}
                        onChange={(e) => handleNewTaskChange('file_url', e.target.value.split(',').map(url => url.trim()))}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewTaskDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewTask} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default QuestDetails;
