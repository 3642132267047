import React, { useState, useEffect } from 'react'
import Timer from '../../components/timer'
import StyledBtn from '../../components/styledBtn'
import AvatarSection from '../../components/avatarSection'
import Leaderboard from '../../components/leaderboard'
import ConectWalletSection from '../../components/conectWalletSection'
import QuestSection from '../../components/questSection'
import Overlay from '../../components/overlay'
import { getTimer } from '../../api/auth'
import { useDisconnect } from 'wagmi'
import toast from 'react-hot-toast'
import { useMediaQuery } from '@mui/material'

export default function Homepage() {
  const [showAvatarSection, setShowAvatarSection] = useState(false)
  const [showConnectSection, setShowConnectSection] = useState(true)
  const [showLeaderboardSection, setShowLeaderboardSection] = useState(false)
  const [showQuestSection, setShowQuestSection] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [expiryDate, setExpiryDate] = useState(new Date())
  const { disconnect } = useDisconnect()
  const isMobile = useMediaQuery('(max-width:800px)')

  useEffect(() => {
    const fetchTimer = async () => {
      try {
        setIsLoading(true)
        const storedTokenData = localStorage.getItem('tokenData')
        if (storedTokenData) {
          const { expiryTime } = JSON.parse(storedTokenData)
          const currentTime = new Date().getTime()

          if (currentTime >= expiryTime) {
            disconnect()
            localStorage.removeItem('tokenData')
            toast.error('Session expired. Please log in again.')
          }
        }
        const timerData = await getTimer()

        // Calculate expiry date based on the current time and the timer data
        const newExpiryDate = new Date()
        newExpiryDate.setDate(newExpiryDate.getDate() + timerData.days)
        newExpiryDate.setHours(newExpiryDate.getHours() + timerData.hours)
        newExpiryDate.setMinutes(newExpiryDate.getMinutes() + timerData.minutes)
        newExpiryDate.setSeconds(newExpiryDate.getSeconds() + timerData.seconds)

        setExpiryDate(newExpiryDate)
      } catch (error) {
        console.error('Error fetching timer:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchTimer()
  }, [disconnect])

  const resetMenuVisibility = () => {
    setShowAvatarSection(false)
    setShowConnectSection(false)
    setShowLeaderboardSection(false)
    setShowQuestSection(false)
  }

  function displayQuestSection() {
    resetMenuVisibility()
    setShowQuestSection(true)
  }

  function displayConnectSection() {
    resetMenuVisibility()
    setShowConnectSection(true)
  }

  function displayLeaderSection() {
    resetMenuVisibility()
    setShowLeaderboardSection(true)
  }

  function displayShowAvatarSection() {
    resetMenuVisibility()
    setShowAvatarSection(true)
  }

  function scroll() {
    displayConnectSection()
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      })
    }, 200)
  }

  return (
    <div className=' z-0'>
      {/* Welcome section */}
      <section className='bg-black bg-opacity-35 pb-[70px]'>
        <div>
          <img src='/images/bountyTemple.png' alt='' className='m-auto h-[260px] w-[260px] mt-[-20px]' />
        </div>
        <div>
          <div className='mb-[30px] mt-[-30px]'>
            <p className='text-[30px] lg:text-[40px] text-center font-extrabold tracking-wide'>REFERRAL PROGRAM</p>
            <p className='text-[20px] text-center font-extralight tracking-wide text-gray-50'>Refer & earn exclusive rewards</p>
          </div>

          <div className='flex flex-col lg:flex-row justify-center gap-28'>
            <div className='flex flex-col mx-auto lg:mx-0 space-y-3'>
              <img className='object-contain w-[240px] lg:w-[290px] h-[170px] lg:h-[200px]' src='/images/queen.png' alt='' />
              <p className='text-center'>Exclusive BT Avatar NFT</p>
            </div>

            <div className='flex flex-col mx-auto lg:mx-0 space-y-3'>
              <img className='object-contain w-[240px] lg:w-[290px] h-[170px] lg:h-[200px]' src='/images/diamond.png' alt='' />
              <p className='text-center'>$1m worth of $TYT Tokens</p>
            </div>

            <div className='flex flex-col mx-auto lg:mx-0 space-y-3'>
              <img className='object-contain w-[240px] lg:w-[290px] h-[170px] lg:h-[200px]' src='/images/meat.png' alt='' />
              <p className='text-center'>Pet Foods</p>
            </div>
          </div>

          <div className='borde flex mt-14'>
            <button className='bg-transparent  m-auto' onClick={scroll}>
              <img className='object-fill  w-[280px] h-[55px]' src='/images/getStarted.png' alt='' />
            </button>
          </div>
        </div>
      </section>

      {/* Referral program section */}
      <section>
        <div className='container mx-auto flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:items-end justify-center lg:justify-around mt-20 lg:pb-20 '>
          <div>
            <p className='text-[25px] lg:text-[45px] text-center font-extrabold tracking-wide'>REFERRAL PROGRAM</p>
          </div>
          <div className='flex flex-col justify-evenly'>
            <p className='hidden lg:block font-bold text-[20px] tracking-wide my-2'>CAMPAIGN ENDS IN</p>
            <Timer
              expiryDate={expiryDate}
              style={'mx-auto lg:mx-0 px-3 lg:px-[80px] py-4 lg:py-2 bg-bg-timer bg-contain bg-no-repeat h-[140px] overflow-auto'}
            />
          </div>
        </div>

        <div className='container mx-auto flex flex-col lg:flex-row justify-center lg:justify-between lg:space-x-10 space-y-8 lg:space-y-0 overflow-auto'>
          <StyledBtn
            onClick={displayConnectSection}
            text='CONNECT WALLET'
            style={`mx-auto w-[250px] lg:w-[500px] h-[70px] font-bold ${
              showConnectSection ? 'bg-bg-btn-hover' : 'bg-bg-btn'
            } bg-contain bg-center bg-no-repeat ${isMobile ? 'text-[16px]' : 'text-[20px]'}`}
          />
          <StyledBtn
            onClick={displayShowAvatarSection}
            text='AVATAR'
            style={`mx-auto w-[250px] lg:w-[500px] h-[70px] font-bold ${
              showAvatarSection ? 'bg-bg-btn-hover' : 'bg-bg-btn'
            } bg-contain bg-center bg-no-repeat ${isMobile ? 'text-[16px]' : 'text-[20px]'}`}
          />
          <StyledBtn
            onClick={displayQuestSection}
            text='QUEST LOG'
            style={`mx-auto w-[250px] lg:w-[500px] h-[70px] font-bold ${showQuestSection ? 'bg-bg-btn-hover' : 'bg-bg-btn'} bg-contain bg-center bg-no-repeat ${
              isMobile ? 'text-[16px]' : 'text-[20px]'
            }`}
          />
          <StyledBtn
            onClick={displayLeaderSection}
            text='LEADERBOARD'
            style={`mx-auto w-[250px] lg:w-[500px] h-[70px] font-bold ${
              showLeaderboardSection ? 'bg-bg-btn-hover' : 'bg-bg-btn'
            } bg-contain bg-center bg-no-repeat ${isMobile ? 'text-[16px]' : 'text-[20px]'}`}
          />
        </div>
      </section>

      <section>
        {showAvatarSection && <AvatarSection />}

        {showLeaderboardSection && <Leaderboard />}

        {showConnectSection && <ConectWalletSection />}

        {showQuestSection && <QuestSection />}

        {/* Global overlay loader if needed */}
        {isLoading && <Overlay text={'Loading....'} />}
      </section>
    </div>
  )
}
