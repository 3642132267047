import React, { useState, useEffect } from 'react';
import { compareImages, fetchImage, mintNft } from '../api/auth';
import toast, { Toaster } from 'react-hot-toast';
import { useAccount, useDisconnect } from "wagmi";
import { ethers } from "ethers";
import { NFTAbi } from "../abi/NFTAbi";
import Overlay from '../components/overlay';
import { useMediaQuery } from '@mui/material';


export default function AvatarSection() {
    const [token, setToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [compareResult, setCompareResult] = useState(null);
    const [imageURL, setImageURL] = useState("");
    const [nftURI, setNftURI] = useState("");
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isCompareLoading, setIsCompareLoading] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const [isMinted, setIsMinted] = useState(false);
    const isMobile = useMediaQuery('(max-width:800px)');

    const { address, isDisconnected } = useAccount();
    const { disconnect } = useDisconnect();

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                setToken(access_token);
                setIsImageLoading(true);
                loadImage(access_token);
            }
        }
    }, []);

    const loadImage = async (token) => {

        try {
            const response = await fetchImage(token);
            console.log(response.image_url);
            setImageURL(response.image_url);
            setNftURI(response.metadata_url);
        } catch (error) {
            console.error('Failed to load image:', error);
            toast.error('Failed to load image');
        } finally {
            setIsImageLoading(false);
        }
    };

    const handleCompareImages = async () => {
        if (!token) {
            toast.error('User is not authenticated');
            return;
        }
        setIsCompareLoading(true);
        try {
            const result = await compareImages(token);
            setCompareResult(result);
            toast.success('Image comparison successful');
        } catch (error) {
            console.error('Failed to compare images:', error);
            toast.error('Failed to compare images');
        } finally {
            setIsCompareLoading(false);
        }
    };

    const mintNFT = async () => {
        if (!address) {
            toast.error("Wallet is not connected");
            return;
        }
        if (!token) {
            toast.error("User is not authenticated");
            return;
        }

        setIsMinting(true);

        try {
            const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, NFTAbi, signer);

            const gasPrice = await provider.getGasPrice();
            const gasLimit = ethers.utils.hexlify(600000);

            const tx = await nftContract.safeMint(address, nftURI, {
                gasPrice: gasPrice.mul(2),
                gasLimit: 600000,
            });

            await tx.wait();
            setIsMinted(true);

            toast.success("NFT minted successfully");
        } catch (error) {
            console.error("Failed to mint NFT", error);
            if (error.code === "CALL_EXCEPTION") {
                alert(`Transaction failed: ${error.message}`);
            }
            toast.error("Failed to mint NFT");
        } finally {
            setIsMinting(false);
        }
    };

    const shareOnDiscord = () => {
        const url = `https://discord.com/channels/@me?content=${encodeURIComponent(nftURI)}`;
        window.open(url, '_blank');
    };

    const shareOnTwitter = () => {
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent("Check out my new NFT minted through Bounty Temple’s $1M Airdrop Referral Program!")}&url=${encodeURIComponent(nftURI)}`;
        window.open(url, '_blank');
    };

    const shareOnTelegram = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(nftURI)}&text=${encodeURIComponent("Check out my new NFT minted through Bounty Temple’s $1M Airdrop Referral Program!")}`;
        window.open(url, '_blank');
    };

    return (
        <div className='relative'>
            <div className={isMobile ? 'container lg:flex space-x-20 justify-center border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 py-28 lg:px-32 lg:mx-auto space-y-6 lg:space-y-0' : 'container lg:flex space-x-20 justify-center border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 py-28 lg:px-32 mx-2 lg:mx-auto space-y-6 lg:space-y-0'}>
                <Toaster />
                <div className='rounded-3xl border border-[#FBB03B] px-10 py-6' style={isMobile ? {marginLeft: '5px', marginRight: '5px'} : {}} >
                    <div className='flex flex-col'>
                        {isImageLoading ? (
                            <Overlay text="Loading image..." />
                        ) : (
                            address ? (
                                <img src={imageURL} alt="NFT Avatar" />
                            ) : (
                                <img src="/images/queenSolo.png" alt="Static Image" />
                            )
                        )}
                        {address && (
                            <button className="w-[230px] h-[60px] bg-transparent mx-auto mt-6" onClick={mintNFT}>
                                <img className='object-fill' src="/images/btnMint.png" alt="Mint NFT" />
                            </button>
                        )}
                    </div>
                </div>

                <div className='flex flex-col' style={isMobile ? { marginLeft: '5px', marginRight: '5px', textAlign: 'center' } : {}}>
                    <p className='text-[30px] font-semibold'>
                        {address ? 'Mint a unique NFT Avatar and share it on social media!' : 'Please Connect your wallet to Mint a unique NFT Avatar'}
                    </p>
                    {isMinted && (
                        <>
                            <button className="w-[230px] h-[60px] bg-transparent mt-6" onClick={shareOnDiscord}>
                                <img className='object-fill' src="/images/btnDiscord.png" alt="Discord" />
                            </button>
                            <button className="w-[230px] h-[60px] bg-transparent mt-6" onClick={shareOnTwitter}>
                                <img className='object-fill' src="/images/btnX.png" alt="Twitter" />
                            </button>
                            <button className="w-[250px] h-[60px] bg-transparent mt-6" onClick={shareOnTelegram}>
                                <img className='object-fill ml-[-20px]' src="/images/btnTelegram.png" alt="Telegram" />
                            </button>
                        </>
                    )}
                    {/* Hide check button when wallet is not connected */}
                    {address && (
                        <button
                            className="w-[300px] h-[80px] bg-transparent mt-20"
                            style={isMobile ? { marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' } : {}}
                            onClick={handleCompareImages}
                            disabled={isCompareLoading}
                        >
                            {isCompareLoading ? (
                                <Overlay text="Comparing images..." />
                            ) : (
                                <img className='object-fill' src="/images/btnCheck.png" alt="Check" />
                            )}
                        </button>
                    )}
                    {compareResult && (
                        <p className="text-white mt-4">{compareResult}</p>
                    )}
                </div>
            </div>
            {isMinting && <Overlay text="Minting NFT..." />}
        </div>
    );
}