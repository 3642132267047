import React, { useEffect, useState } from 'react';
import Quest from './quest';
import { fetchMasterQuests, fetchUserInfo, fetchLeaderboard } from '../api/auth';
import toast, { Toaster } from 'react-hot-toast';
import Overlay from '../components/overlay';
import { useDisconnect, useAccount } from "wagmi";
import { QuestDetail } from './QuestDetail';
import { useMediaQuery } from '@mui/material';

export default function QuestSection() {
    const [token, setToken] = useState(null);
    const [quests, setQuests] = useState([]);
    const [pointsAccumulated, setPointsAccumulated] = useState(0);
    const [questsCompleted, setQuestsCompleted] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedQuest, setSelectedQuest] = useState(null);
    const [hasLinkedEmailAndSocial, setHasLinkedEmailAndSocial] = useState(false); // Assume true initially
    const { address } = useAccount();
    const isMobile = useMediaQuery('(max-width:800px)');

    const { disconnect } = useDisconnect();

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                setToken(access_token);
                setIsLoading(true);
                loadQuests(access_token);
                loadUserInfo(access_token);
                checkUserLinkStatus(access_token);
            }
        }
    }, []);

    //Run everytime component is mounted checkUserLinkStatus
    useEffect(() => {
        if (token) {
            checkUserLinkStatus(token);
        }
    }, []);

    // Function to check if the user has linked their email and social handles
    const checkUserLinkStatus = async (token) => {
        try {
            const leaderboardData = await fetchLeaderboard(token, 100); // Fetch leaderboard data (with a reasonable limit)

            if (leaderboardData && leaderboardData.length > 0) {

                if (!address) {
                    return;
                }

                // Find the current user's data within the leaderboard
                const currentUser = leaderboardData.find(user => user.address.toLowerCase() === address.toLowerCase());

                if (currentUser) {
                    const hasEmail = currentUser.email && currentUser.email.length > 0;
                    const hasSocialHandles = currentUser.socials && currentUser.socials.length > 0;


                    setHasLinkedEmailAndSocial(hasEmail && hasSocialHandles); // Update state based on the check
                } else {
                    console.error('Current user not found in leaderboard.');
                }
            } else {
                console.error('Leaderboard data is empty or undefined.');
            }
        } catch (error) {
            console.error('Failed to check user link status:', error);
        }
    };



    const loadQuests = async (token) => {
        try {
            const data = await fetchMasterQuests(token);
            setQuests(data);
        } catch (error) {
            console.error('Failed to fetch quests:', error);
            toast.error('Failed to fetch quests');
        } finally {
            setIsLoading(false);
        }
    };

    const loadUserInfo = async (token) => {
        try {
            const data = await fetchUserInfo(token);
            setQuestsCompleted(data.quests_completed.length);
            setPointsAccumulated(data.points);
        } catch (error) {
            console.error('Failed to fetch user info:', error);
            toast.error('Failed to fetch user info');
        }
    };

    const handleQuestClick = (quest) => {
        setSelectedQuest(quest);
    };

    const closeQuestDetail = () => {
        setSelectedQuest(null);
    };

    const calculatePoints = (subQuestArray) => {
        let points = 0;
        subQuestArray.forEach((quest) => {
            points += quest.points;
        });
        return points;
    };

    return (
        <div className='relative'>
            {isLoading && <Overlay text="Loading quests..." />}
            <div className='relative container mx-auto border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 pb-28 overflow-auto'>
                <Toaster />
                {selectedQuest && (
                    <QuestDetail quest={selectedQuest} onClose={closeQuestDetail} />
                )}
                <div className={isMobile ? 'flex flex-col lg:flex-row lg:space-x-10 min-h-[490px] space-y-10 lg:space-y-0 pt-20 mx-5' : 'flex flex-col lg:flex-row lg:space-x-10 min-h-[490px] space-y-10 lg:space-y-0 pt-20 mx-32'}>
                    <div className="flex flex-col space-y-10 self-center">
                        <div>
                            <p className='text-[30px] text-[#FBB03B] text-center font-semibold'>{pointsAccumulated}</p>
                            <p className='text-[30px] tracking-wider text-center'>Points Accumulated</p>
                        </div>
                        <div>
                            <p className='text-[30px] text-[#FBB03B] text-center font-semibold'>{questsCompleted}</p>
                            <p className='text-[30px] tracking-wider text-center'>Quests Completed</p>
                        </div>
                    </div>
                    <div className="w-[3px] bg-[#FBB03B]"></div>
                    <div className={isMobile ? "flex flex-col space-y-8 w-full text-center" : "flex flex-col space-y-8 w-full"}>
                        {/* Conditionally render quests or the message */}
                        {hasLinkedEmailAndSocial ? (
                            quests.length > 0 ? (
                                quests.map((quest) => (
                                    <Quest
                                        key={quest._id}
                                        badgeText={quest.badgeText || 'NEW'}
                                        badgeStyle='bg-[#8CC63F] px-8 text-[16px] font-semibold text-black rounded-3xl'
                                        statusText={quest.isNew ? 'NEW' : 'ONGOING'}
                                        statusStyle='bg-white px-5 xs:px-12 text-[12px] xs:text-[16px] font-semibold text-black rounded-3xl'
                                        title={quest.title}
                                        description={quest.description}
                                        points={calculatePoints(quest.quest_id)}
                                        endDate={quest.ends_at}
                                        onQuestClick={() => handleQuestClick(quest)}
                                    />
                                ))
                            ) : (
                                <p className='text-[18px] text-center'>No quests available.</p>
                            )
                        ) : (
                            <p className='text-[18px] text-center text-red-500'>
                                You must link your email and social handles to access quests.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
