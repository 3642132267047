import React, { useState, useEffect } from 'react';
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, TextField, Checkbox, Box, Typography
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Sidebar from './sidebar';
import {
    fetchLeaderboard, createQuest, banUser, verifyUser, checkUserHistory, fetchIncompleteQuests, completeQuest, awardManualPoints, fetchMasterQuests, setTimer
} from '../api/auth';
import Overlay from '../components/overlay';
import toast, { Toaster } from 'react-hot-toast';
import { useDisconnect } from "wagmi";
import CreateQuestForm from './CreateQuestForm';
import QuestDetails from './QuestDetails';
import { QuestModal } from './questmodal';

const AdminPanel = () => {

    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState([]);
    const [masterQuests, setMasterQuests] = useState([]);
    const [showCreateQuest, setShowCreateQuest] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [incompleteQuests, setIncompleteQuests] = useState([]);
    const [pointsAction, setPointsAction] = useState(null); // 'approve' or 'reject'
    const [pointsValue, setPointsValue] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [questFileUrl, setQuestFileUrl] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false); // For Add Quest
    const [newQuest, setNewQuest] = useState({ tag_line: '', description: '', points: 0, is_auto: false });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedQuest, setSelectedQuest] = useState(null);
    const [selectedQuests, setSelectedQuests] = useState([]);
    const [userAnchorEl, setUserAnchorEl] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [questAnchorEl, setQuestAnchorEl] = useState(null);
    const [isTimerLoading, setIsTimerLoading] = useState(false);
    const [timer, setTimerVar] = useState('');

    const handleSetTimer = async () => {
        try {
            // Validate and format the date
            const parsedTime = new Date(timer); // `timerValue` should be a valid date string or Date object
            if (isNaN(parsedTime)) {
                toast.error('Invalid date. Please enter a valid date.');
                return;
            }

            const formattedTime = parsedTime.toISOString(); // Convert to ISO format

            // Call the API to set the timer
            await setTimer(token, formattedTime);
            toast.success('Timer set successfully');
        } catch (error) {
            console.error('Error setting timer:', error);
            toast.error('Failed to set timer. Please try again.');
        }
    };


    // Handle user menu
    const handleUserMenuClick = (event, user) => {
        setUserAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleUserMenuClose = () => {
        setUserAnchorEl(null);
        setSelectedUser(null);
    };

    const handleQuestRowClick = (quest) => {
        setSelectedQuest(quest); // Set the selected quest
    };

    const handleBackToQuests = () => {
        setSelectedQuest(null); // Clear the selected quest to go back to the quest list
        setShowCreateQuest(false); // Close the CreateQuestForm
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    // Function to handle modal rejection
    const handleReject = () => {
        setIsModalOpen(false);
    };

    // Function to handle modal acceptance
    const handleAccept = () => {
        setIsModalOpen(false);
    };

    const { disconnect } = useDisconnect();

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                setToken(access_token);
                setIsLoading(true);
                fetchData(access_token);
            }
        }
    }, [activeTab]);

    useEffect(() => {
        // Clear the selected quest whenever the tab changes
        setSelectedQuest(null);
    }, [activeTab]);

    const fetchData = async (token) => {
        try {
            setIsLoading(true);
            const response = await fetchLeaderboard(token, 20);
            setData(Array.isArray(response) ? response : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (activeTab === 4 && token) {
            fetchIncompleteQuestsData(token);
        }
    }, [activeTab, token]);

    const fetchIncompleteQuestsData = async (token) => {
        try {
            setIsLoading(true); // Start loading
            const quests = await fetchIncompleteQuests(); // Use your API endpoint for incomplete quests
            setIncompleteQuests(Array.isArray(quests) ? quests : []);
        } catch (error) {
            console.error('Error fetching incomplete quests:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedQuest(null);
    };

    const handleQuestCreation = async () => {
        try {
            setIsLoading(true);
            const response = await createQuest(token, { ...newQuest, file_url: questFileUrl });

            if (response.status === 401) {
                toast.error('Unauthorized. Please log in again.');
                disconnect();
                return;
            }

            setDialogOpen(false);
            await fetchMasterQuestsData(token); // Refetch quests data after creating a quest
        } catch (error) {
            console.error('Error creating quest:', error);
            toast.error('Error creating quest');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                setToken(access_token);
                setIsLoading(true);
                fetchMasterQuestsData(access_token);
            }
        }
    }, [activeTab]);

    const fetchMasterQuestsData = async (token) => {
        try {
            setIsLoading(true);
            const response = await fetchMasterQuests(token);

            // Process each master quest to calculate the total points and number of tasks
            const processedQuests = response.map(quest => {
                const noOfTasks = quest.quest_id ? quest.quest_id.length : 0;
                const totalPoints = quest.quest_id
                    ? quest.quest_id.reduce((sum, subQuest) => sum + (subQuest.points || 0), 0)
                    : 0;
                return { ...quest, noOfTasks, totalPoints };
            });

            setMasterQuests(processedQuests);
        } catch (error) {
            console.error('Error fetching master quests:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleMenuClick = (event, row, action) => {
        setSelectedUserId(row._id);
        setSelectedQuest(row); // Assuming row contains the quest data
        setPointsAction(action); // Track the action ('approve' or 'reject')
        setAnchorEl(event.currentTarget);
    };

    const handleQuestDelete = (deletedQuestId) => {
        setMasterQuests((prevQuests) => prevQuests.filter((quest) => quest._id !== deletedQuestId));
        setSelectedQuest(null); // Clear the selected quest
    };

    const getSocialHandle = (socials, platform) => {
        const social = socials?.find(s => s.platform === platform);
        return social ? social.handle : '-';
    };

    const getUserEmailByWalletAddress = (walletAddress) => {
        const user = data.find(user => user.address === walletAddress);
        return user ? user.email : '-';
    };

    const getValueOrDash = (value) => {
        return value ? value : '-';
    };

    const handleBanUser = async (userId) => {
        try {
            await banUser(token, userId);
            toast.success('User banned successfully');
            fetchData(token);
        } catch (error) {
            console.error('Error banning user:', error);
            toast.error('Error banning user');
        }
    };

    const handleVerifyUser = async (userId) => {
        try {
            await verifyUser(token, userId);
            toast.success('User verified successfully');
            fetchData(token);
        } catch (error) {
            console.error('Error verifying user:', error);
            toast.error('Error verifying user');
        }
    };

    const handleCheckHistory = async (userId) => {
        try {
            const history = await checkUserHistory(token, userId);
            toast.success('User history fetched successfully');
        } catch (error) {
            console.error('Error fetching user history:', error);
            toast.error('Error fetching user history');
        }
    };
    const calculatePoints = (subQuestArray) => {
        let points = 0;
        subQuestArray.forEach((quest) => {
            points += quest.points;
        });
        return points;
    }

    const handleCheckboxChange = (userId, questId) => {
        setSelectedQuests((prevSelected) => {
            const isSelected = prevSelected.some(
                (q) => q.userId === userId && q.questId === questId
            );
            if (isSelected) {
                return prevSelected.filter(
                    (q) => !(q.userId === userId && q.questId === questId)
                );
            } else {
                return [...prevSelected, { userId, questId }];
            }
        });
    };

    const handleQuestSelect = (questId) => {
        setSelectedQuests(prevSelected =>
            prevSelected.includes(questId)
                ? prevSelected.filter(id => id !== questId)
                : [...prevSelected, questId]
        );
    };

    const handleApproveSelected = async () => {
        try {
            await Promise.all(selectedQuests.map(id => completeQuest(token, id)));
            toast.success('Selected quests approved');
            fetchData(token);
        } catch (error) {
            toast.error('Error approving quests');
            console.error('Error approving quests:', error);
        }
    };

    const handleRejectSelected = async () => {
        // Logic to reject selected quests
        toast.success('Selected quests rejected');
        fetchData(token);
    };

    const handleDeleteSelected = async () => {
        // Logic to delete selected quests
        toast.success('Selected quests deleted');
        fetchData(token);
    };

    const handlePointsChange = (event) => {
        setPointsValue(event.target.value);
    };

    const handleMenuClicks = async (action) => {
        if (selectedQuests.length === 0) {
            toast.error("No quests selected");
            return;
        }

        try {
            setIsLoading(true);

            // Iterate over each selected quest and perform the approve or reject action
            for (const quest of selectedQuests) {
                const points = action === 'approve' ? pointsValue : 0; // Assign points based on action
                await awardManualPoints(token, {
                    awards: [{ userId: quest.userId, questId: quest.questId }],
                    points,
                });
            }

            toast.success(`Selected quests ${action === 'approve' ? 'approved' : 'rejected'} successfully`);

            // Clear selected quests and refresh the incomplete quests data
            setSelectedQuests([]);
            await fetchIncompleteQuestsData(token); // Refresh the data after action
        } catch (error) {
            console.error(`Error ${action === 'approve' ? 'approving' : 'rejecting'} quests:`, error);
            toast.error(`Error ${action === 'approve' ? 'approving' : 'rejecting'} quests`);
        } finally {
            setIsLoading(false);
            handleMenuClose(); // Close the menu after action
        }
    };

    function updateActiveTab(activeTab) {
        setActiveTab(activeTab);
        setSelectedQuest(null)
    }


    return (
        <div className='bg-white px-5 pb-8'>
            <div className='md:flex md:space-x-10 items-center px-10'>
                <Toaster />
                <QuestModal
                    title={"Title"}
                    description={"Description"}
                    isOpen={isModalOpen}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    onReject={handleReject}
                    imgUrl="https://st2.depositphotos.com/2291517/8144/i/950/depositphotos_81440882-stock-photo-fees-concept.jpg"
                />
                <img src="/images/bountyTemple.png" alt="" className='w-[200px]' />
                <h1 className='text-black text-4xl font-bold mb-5 md:mb-0 '>
                    REFERRAL PROGRAM DASHBOARD
                </h1>
            </div>
            <div style={{ display: 'flex' }} className='bg-gray-300 rounded-md'>
                <Sidebar setActiveTab={updateActiveTab} currentTab={activeTab} />
                <div className='w-1 bg-gray-400'></div>
                <Container sx={{ overflow: 'auto' }}>
                    {isLoading && <Overlay text="Loading..." />}

                    {/* Conditionally render QuestDetail or the quest table */}
                    {selectedQuest ? (
                        <QuestDetails
                            quest={selectedQuest}
                            onBack={handleBackToQuests}
                            token={token}
                            onQuestDelete={handleQuestDelete}
                        />
                    ) : (
                        <>
                            {/* Tab 0: Leaderboard */}
                            {activeTab === 0 && (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='!shadow-none bg-gray-300'>
                                                {['Wallet Address', 'Email', 'Telegram', 'Discord', 'X.com', 'Points', 'More'].map(header => (
                                                    <TableCell className='!font-bold' key={header}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{getValueOrDash(row.address)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.email)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.telegramId, 'telegram')}</TableCell>
                                                    <TableCell>{getSocialHandle(row.socials, 'discord')}</TableCell>
                                                    <TableCell>{getSocialHandle(row.socials, 'twitter')}</TableCell>
                                                    {/* <TableCell>{getValueOrDash(row.referrer)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.referees_invited)}</TableCell> */}
                                                    <TableCell>{getValueOrDash(row.points)}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={(event) => handleUserMenuClick(event, row)}>
                                                            <MoreVertIcon style={{ color: 'black' }} />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={userAnchorEl}
                                                            open={Boolean(userAnchorEl)}
                                                            onClose={handleUserMenuClose}
                                                        >
                                                            <MenuItem sx={{ color: 'black' }} onClick={() => handleBanUser(selectedUser._id)}>Ban User</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={() => handleVerifyUser(selectedUser._id)}>Verify</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={() => handleCheckHistory(selectedUser._id)}>Check History</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleUserMenuClose}>Close Menu</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            {/* Tab 1: NFT Leaderboard */}
                            {activeTab === 1 && (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='!shadow-none bg-gray-300'>
                                                {['Wallet Address', 'Email', 'NFT #', 'Discord', 'X.com', 'Telegram'].map(header => (
                                                    <TableCell className='!font-bold' key={header}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{getValueOrDash(row.address)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.email)}</TableCell>
                                                    <TableCell>{row.nfts ? row.nfts.length : '-'}</TableCell>
                                                    <TableCell>{getSocialHandle(row.socials, 'discord')}</TableCell>
                                                    <TableCell>{getSocialHandle(row.socials, 'twitter')}</TableCell>
                                                    <TableCell>{getValueOrDash(row.telegramId, 'telegram')}</TableCell>
                                                    {/* <TableCell>
                                                        <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                                            <MoreVertIcon style={{ color: 'black' }} />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem sx={{ color: 'black' }} onClick={() => handleCheckHistory(row._id)}>Check History</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Mint NFT</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Delete NFT</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Close Menu</MenuItem>
                                                        </Menu>
                                                    </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            {/* Tab 2: Referrals */}
                            {activeTab === 2 && (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='!shadow-none bg-gray-300'>
                                                {['Wallet Address', 'Email', 'Referral Link', 'Referrer', 'Referees', 'Points'].map(header => (
                                                    <TableCell className='!font-bold' key={header}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{getValueOrDash(row.address)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.email)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.referral_code)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.referrer)}</TableCell>
                                                    <TableCell>{getValueOrDash(row.referees_invited)}</TableCell>
                                                    {/* Replace Tickets with Points */}
                                                    <TableCell>{getValueOrDash(row.points)}</TableCell>
                                                    {/* <TableCell>
                                                        <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                                            <MoreVertIcon style={{ color: 'black' }} />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem sx={{ color: 'black' }} onClick={() => handleCheckHistory(row._id)}>Check History</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Add Points</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Remove Points</MenuItem>
                                                            <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Close Menu</MenuItem>
                                                        </Menu>
                                                    </TableCell> */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            {activeTab === 3 && (
                                <>
                                    {showCreateQuest ? (
                                        <CreateQuestForm
                                            onConfirm={(newQuest) => {
                                                setNewQuest(newQuest);
                                                handleQuestCreation();
                                                setShowCreateQuest(false);
                                            }}
                                            onCancel={() => setShowCreateQuest(false)}
                                            token={token}
                                        />
                                    ) : (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                onClick={() => setShowCreateQuest(true)}
                                                sx={{ mb: 2, float: 'right' }}
                                            >
                                                Create Quest
                                            </Button>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className='!shadow-none bg-gray-300'>
                                                            {['Quest Name', 'Date', 'Total Points Given', 'No. of Tasks'].map(header => (
                                                                <TableCell className='!font-bold' key={header}>{header}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {masterQuests.map((quest, index) => (
                                                            <TableRow key={index} onClick={() => handleQuestRowClick(quest)}>
                                                                <TableCell>{quest.tag_line}</TableCell>
                                                                <TableCell>{new Date(quest.createdAt).toLocaleDateString()}</TableCell>
                                                                <TableCell>{quest.totalPoints || 0}</TableCell>
                                                                <TableCell>{quest.noOfTasks || 0}</TableCell>
                                                                {/* <TableCell>
                                                                    <IconButton onClick={(event) => handleQuestMenuClick(event, quest)}>
                                                                        <MoreVertIcon style={{ color: 'black' }} />
                                                                    </IconButton>
                                                                    <Menu
                                                                        anchorEl={questAnchorEl}
                                                                        open={Boolean(questAnchorEl)}
                                                                        onClose={handleQuestMenuClose}
                                                                    >
                                                                        <MenuItem sx={{ color: 'black' }}>Complete Quest</MenuItem>
                                                                        <MenuItem sx={{ color: 'black' }} onClick={handleQuestMenuClose}>Edit Quest</MenuItem>
                                                                        <MenuItem sx={{ color: 'black' }} onClick={handleQuestMenuClose}>Delete Quest</MenuItem>
                                                                    </Menu>
                                                                </TableCell> */}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </>
                            )}

                            {/* Tab 4: Incomplete Quests */}
                            {activeTab === 4 && (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className="!shadow-none bg-gray-300">
                                                {['Quest Name', 'Wallet Address', 'User Email', 'Links', 'Approved?', 'Total Points', 'Select'].map(header => (
                                                    <TableCell className="!font-bold" key={header}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {incompleteQuests.map((user, userIndex) => (
                                                user.incompleteQuests.map((quest, questIndex) => {
                                                    const questId = quest.quest_id;
                                                    const questTagLine = questId ? questId.tag_line : 'Unknown Quest';
                                                    const questPoints = questId ? questId.points : 0;
                                                    const fileUrls = user.questUploads?.find(upload => upload.quest_id === questId?._id)?.file_url || [];

                                                    return (
                                                        <TableRow key={`${userIndex}-${questIndex}`}>
                                                            <TableCell>{questTagLine}</TableCell>
                                                            <TableCell>{user.address}</TableCell>
                                                            <TableCell>{user.email || '-'}</TableCell>
                                                            <TableCell>
                                                                {fileUrls.length > 0 ? (
                                                                    fileUrls.map((url, idx) => {
                                                                        // Ensure url is valid and non-null before checking startsWith
                                                                        const validUrl = url && typeof url === 'string'
                                                                            ? (url.startsWith('http') ? url : `http://${url}`)
                                                                            : '#';
                                                                        return (
                                                                            <a key={idx} href={validUrl} target="_blank" rel="noopener noreferrer">
                                                                                [{idx + 1}]
                                                                            </a>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    '-'
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{quest.given ? 'Y' : 'N'}</TableCell>
                                                            <TableCell>{questPoints}</TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    checked={selectedQuests.some(
                                                                        (q) => q.userId === user._id && q.questId === questId?._id
                                                                    )}
                                                                    onChange={() => handleCheckboxChange(user._id, questId?._id)}
                                                                    disabled={!questId} // Disable if quest_id is null
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {selectedQuests.length > 0 && (
                                        <Box mt={2} display="flex" alignItems="center">
                                            <Typography variant="body1">Selected: {selectedQuests.length}</Typography>
                                            <TextField
                                                label="Points"
                                                type="number"
                                                value={pointsValue}
                                                onChange={handlePointsChange}
                                                sx={{ mx: 2, width: '100px' }}
                                            />
                                            <IconButton onClick={() => handleMenuClicks('approve')}>
                                                Approve
                                            </IconButton>
                                            <IconButton onClick={() => handleMenuClicks('reject')}>
                                                Reject
                                            </IconButton>
                                            <Button variant="contained" color="error" onClick={handleDeleteSelected} sx={{ mx: 1 }}>
                                                Delete
                                            </Button>
                                        </Box>
                                    )}
                                </TableContainer>
                            )}

                            {activeTab === 5 && (
                                <Container component={Paper} sx={{ padding: '20px', marginTop: '20px' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Set Timer
                                    </Typography>
                                    <TextField
                                        label="Timer"
                                        type="datetime-local"
                                        fullWidth
                                        value={timer}
                                        onChange={(e) => setTimerVar(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={{ marginBottom: '20px' }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSetTimer}
                                        disabled={isTimerLoading}
                                    >
                                        {isTimerLoading ? 'Setting Timer...' : 'Set Timer'}
                                    </Button>
                                </Container>
                            )}

                        </>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default AdminPanel;