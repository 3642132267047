import axios from 'axios';
import toast from 'react-hot-toast';

// Create an instance of axios
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to check token expiry and set the Authorization header
api.interceptors.request.use(
  async (config) => {
    const storedTokenData = localStorage.getItem('tokenData');
    if (storedTokenData) {
      const { access_token, expiryTime } = JSON.parse(storedTokenData);
      const currentTime = new Date().getTime();

      // Check if the token is expired
      if (currentTime >= expiryTime) {
        // If token is expired, remove it and show an error message
        localStorage.removeItem('tokenData');
        toast.error('Session expired. Please log in again.');

        // Optional: Redirect the user to the login page
        // window.location.href = '/login'; // Uncomment if you want to redirect to login

        // Return a rejection to prevent the request from being sent
        return Promise.reject(new Error('Token expired'));
      } else {
        // If the token is valid, set it in the Authorization header
        config.headers['Authorization'] = `Bearer ${access_token}`;
      }
    }
    return config;
  },
  (error) => {
    // Handle any errors that occur during request setup
    return Promise.reject(error);
  }
);

// Authentication and User-related API Calls

export const loginWithWeb3 = async (signature, message, referralCode) => {
  try {
    const response = await api.post('/auth/web3-login', { signature, message }, {
      params: { referral_code: referralCode },
    });
    return response.data;
  } catch (error) {
    console.error('Login with Web3 failed:', error);

  }
};

export const linkEmail = async (token, email) => {
  try {
    const response = await api.post(
      '/auth/link-email',
      { email },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Link email failed:', error);
    throw error;  // Re-throw the error so that it's caught in the frontend
  }
};

export const handleSocials = async (token, socials) => {
  try {
    const response = await api.post(
      '/auth/handle-socials',
      socials,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Handle socials failed:', error);
    throw error;  // Re-throw the error so that it's caught in the frontend
  }
};


export const compareImages = async (token) => {
  try {
    const response = await api.get('/auth/compare-images', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Compare images failed:', error);
  }
};

export const fetchReferralCode = async (token) => {
  try {
    const response = await api.get('/auth/referral-code', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch referral code failed:', error);

  }
};

export const fetchQuests = async (token) => {
  try {
    const response = await api.get('/auth/quests', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch quests failed:', error);

  }
};

export const fetchUserInfo = async (token) => {
  try {
    const response = await api.get('/auth/user-info', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch user info failed:', error);

  }
};

export const fetchImage = async (token) => {
  try {
    const response = await api.post('/auth/mint-nft', {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Mint NFT failed:', error);

  }
};

// Admin and Quest-related API Calls

export const fetchLeaderboard = async (token, limit) => {
  try {
    const response = await api.get('/auth/leaderboard', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { limit },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch leaderboard failed:', error);

  }
};

export const createQuest = async (token, questData) => {
  try {
    const response = await api.post('/auth/quest', questData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create quest failed:', error);

  }
};

export const updateUserStatus = async (token, userId, status) => {
  try {
    const response = await api.patch(`/auth/user/${userId}/status`, { status }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update user status failed:', error);

  }
};

export const trackUser = async (token, trackingData) => {
  try {
    const response = await api.post('/auth/user/track', trackingData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Track user failed:', error);

  }
};

// New functions for additional options
export const banUser = async (token, userId) => {
  try {
    const response = await updateUserStatus(token, userId, 'banned');
    return response;
  } catch (error) {
    console.error('Ban user failed:', error);

  }
};

export const verifyUser = async (token, userId) => {
  try {
    const response = await updateUserStatus(token, userId, 'verified');
    return response;
  } catch (error) {
    console.error('Verify user failed:', error);

  }
};

export const checkUserHistory = async (token, userId) => {
  try {
    const response = await api.get(`/auth/user/${userId}/history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Check history failed:', error);

  }
};

export const addUserPoints = async (token, address, points) => {
  try {
    const trackingData = {
      address: address,
      type: 'points',
      action: points > 0 ? 'add' : 'remove',
      item: 'points',
      quantity: points.toString(),
    };
    const response = await trackUser(token, trackingData);
    return response;
  } catch (error) {
    console.error('Add points failed:', error);

  }
};

export const deleteQuest = async (token, questId) => {
  try {
    const response = await api.delete(`/auth/quest/${questId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Delete quest failed:', error);

  }
};

export const updateQuest = async (token, questId, questData) => {
  try {
    const response = await api.patch(`/auth/quest/${questId}`, questData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update quest failed:', error);

  }
};

export const handleQuestCompletion = async (token, questId) => {
  try {
    const response = await api.patch(`/auth/quest/${questId}/completed`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Complete quest failed:', error);

  }
};

export const fetchIncompleteQuests = async () => {
  try {
    // const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}auth/incomplete-quests`, {
    //   method: 'GET',
    // });
    const response = await api.get('/auth/incomplete-quests');
    return await response.data;
  } catch (error) {

  }
};

export const completeQuest = async (token, questId) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}auth/complete-quest/${questId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response;
};

export const awardManualPoints = async (token, data) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}auth/award-points`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error('Failed to award points');
  }

  return await response.json();
};


export const deductPoints = async (token, deductData) => {
  try {
    const response = await api.post('/auth/user/deductPoints', deductData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Deduct points failed:', error);

  }
};

export const fetchMasterQuests = async (token) => {
  try {
    const response = await api.get('/auth/master-quests', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch master quests failed:', error);

  }
};

export const linkMasterQuest = async (token, questId, masterQuestId) => {
  try {
    const response = await api.post(`/auth/link-master-quest/${questId}/${masterQuestId}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Link master quest failed:', error);
  }
};

export const createMasterQuest = async (token, questData) => {
  try {
    const response = await api.post('/auth/master-quest', questData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create master quest failed:', error);
  }
};

export const updateQuestion = async (questId, body) => {
  try {
    const response = await api.patch(`auth/quest/${questId}/completed`, body);
    return response.data;
  } catch (error) {
    console.error('Update question failed:', error);
    toast.error(`Failed to submit proof. ${error.response.data.message}`);
  }
};

export const fetchMasterQuestById = async (token, questId) => {
  try {
    const response = await api.get(`/auth/master-quest-by-id/${questId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Fetch master quest by ID failed:', error);
    throw error;
  }
};

export const deleteMasterQuest = async (token, masterQuestId) => {
  try {
    const response = await api.delete(`/auth/master-quest/${masterQuestId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Delete master quest failed:', error);
    throw error;
  }
};

export const editMasterQuest = async (token, masterQuestId, questData) => {
  try {
    const response = await api.patch(`/auth/master-quest/${masterQuestId}`, questData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Edit master quest failed:', error);
    throw error;
  }
};

export const setTimer = async (token, time) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_API_URL}auth/set_timer`,
      { time: new Date(time).toISOString() },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error setting timer:', error);
    throw error;
  }
};

export const getTimer = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}auth/get_timer`);
    return response.data;
  } catch (error) {
    console.error('Error fetching timer:', error);
    throw error;
  }
};
