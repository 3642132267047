import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar';
import Homepage from './pages/Homepage/homepage';
import AdminPanel from './components/adminPanel';
import Userdetails from './pages/Homepage/userdetails';

function App() {
  return (
    <Router>
      <div className="bg-bg-image bg-cover bg-repeat-y pb-10 overflow-auto">
        <Routes>
          <Route exact path="/" element={<><Navbar /><Homepage /></>} />
          <Route path="/referral/:referralCode" element={<><Navbar /><Homepage /></>} />
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/user/:id" element={<Userdetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
