import React, { useState } from 'react';
import { Button, TextField, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { createMasterQuest, createQuest, linkMasterQuest } from '../api/auth'; // Import the necessary API functions

const CreateQuestForm = ({ onConfirm, onCancel, token }) => {
    const [questName, setQuestName] = useState('');
    const [questDescription, setQuestDescription] = useState('');
    const [tasks, setTasks] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString()); // Master quest start date
    const [endDate, setEndDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString()); // Master quest end date

    const handleConfirm = async () => {
        try {
            // Step 1: Create the master quest
            const masterQuestResponse = await createMasterQuest(token, {
                tag_line: questName,
                description: questDescription,
                starts_at: startDate, // Use the master quest start date
                ends_at: endDate // Use the master quest end date
            });

            const masterQuestId = masterQuestResponse._id;

            // Step 2: Create individual quests and link them to the master quest
            for (const task of tasks) {
                const questData = {
                    tag_line: task.tag_line, // or any relevant field
                    description: task.value,
                    points: task.points,
                    file_url: task.fileUrls || [], // Storing URLs here
                    task_type: task.type,
                    total_uploads: task.totalUploads || 1,
                    is_auto: task.isAuto || false,
                    starts_at: startDate, // Inherit from master quest
                    ends_at: endDate, // Inherit from master quest
                };

                console.log(questData)
                const questResponse = await createQuest(token, questData);

                // Step 3: Link the created quest to the master quest
                await linkMasterQuest(token, questResponse._id, masterQuestId);
            }

            onConfirm(masterQuestResponse.data);
        } catch (error) {
            console.error('Error creating quest:', error);
            // Handle error (e.g., show error message to the user)
        }
    };

    const handleTaskChange = (index, field, value) => {
        const newTasks = [...tasks];
        newTasks[index][field] = value;
        setTasks(newTasks);
    };

    const handleTaskTypeChange = (index, type) => {
        const newTasks = [...tasks];
        newTasks[index] = {
            tag_line: '',
            type,
            value: '',
            points: 0,
            fileUrls: [],
            task_type: type,
            totalUploads: 1,
            isAuto: false,
        };  // Reset task fields when type changes
        setTasks(newTasks);
    };

    const handleAddTask = () => {
        setTasks([...tasks, { type: '', value: '', points: 0, fileUrls: [], totalUploads: 1, isAuto: false }]);
    };

    return (
        <div className='bg-gray-200 p-8 rounded-md'>
            <h2 className='text-2xl font-bold mb-5'>CREATE NEW QUEST</h2>
            <TextField
                label="Quest Name"
                fullWidth
                value={questName}
                onChange={(e) => setQuestName(e.target.value)}
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="Quest Description"
                fullWidth
                multiline
                rows={4}
                value={questDescription}
                onChange={(e) => setQuestDescription(e.target.value)}
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="Start Date"
                type="datetime-local"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ marginBottom: '20px' }}
            />
            <TextField
                label="End Date"
                type="datetime-local"
                fullWidth
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ marginBottom: '20px' }}
            />
            {tasks.map((task, index) => (
                <div key={index} className='mb-5'>
                    <h4 className='text-lg font-bold mb-2'>Select Task Type</h4>
                    <Grid container spacing={2} sx={{ marginBottom: '10px' }}>
                        <Grid item>
                            <Button
                                variant={task.task_type === 'File Upload' ? 'contained' : 'outlined'}
                                onClick={() => handleTaskTypeChange(index, 'File Upload')}
                                sx={{
                                    backgroundColor: task.task_type === 'File Upload' ? '#DABFFF' : 'transparent',
                                    color: '#333',
                                }}
                            >
                                File Upload
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={task.task_type === 'Provide a Link' ? 'contained' : 'outlined'}
                                onClick={() => handleTaskTypeChange(index, 'Provide a Link')}
                                sx={{
                                    backgroundColor: task.task_type === 'Provide a Link' ? '#FDCB6E' : 'transparent',
                                    color: '#333',
                                }}
                            >
                                Provide a Link
                            </Button>
                        </Grid>
                        {/* Add more task types as needed */}
                    </Grid>

                    {task.task_type === 'File Upload' && (
                        <div>
                            <TextField
                                label="Task Title"
                                fullWidth
                                value={task.tag_line}
                                onChange={(e) => handleTaskChange(index, 'tag_line', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="Task Description"
                                fullWidth
                                value={task.value}
                                onChange={(e) => handleTaskChange(index, 'value', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="Points"
                                type="number"
                                value={task.points}
                                onChange={(e) => handleTaskChange(index, 'points', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="Max Files Per Upload"
                                type="number"
                                value={task.totalUploads}
                                onChange={(e) => handleTaskChange(index, 'totalUploads', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="File URLs (Comma Separated)"
                                fullWidth
                                value={task.fileUrls.join(',')}
                                onChange={(e) => handleTaskChange(index, 'fileUrls', e.target.value.split(','))}
                                sx={{ marginBottom: '20px' }}
                            />
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={false} // Always unchecked
                                            disabled // Checkbox is disabled and does nothing
                                        />
                                    }
                                    label="Images (PNG, JPEG)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={false} // Always unchecked
                                            disabled // Checkbox is disabled and does nothing
                                        />
                                    }
                                    label="Videos (mov, mp4)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={false} // Always unchecked
                                            disabled // Checkbox is disabled and does nothing
                                        />
                                    }
                                    label="PDF"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={false} // Always unchecked
                                            disabled // Checkbox is disabled and does nothing
                                        />
                                    }
                                    label="All"
                                />
                            </div>
                        </div>
                    )}

                    {task.task_type === 'Provide a Link' && (
                        <div>
                            <TextField
                                label="Task Title"
                                fullWidth
                                value={task.tag_line}
                                onChange={(e) => handleTaskChange(index, 'tag_line', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="Task Description"
                                fullWidth
                                value={task.value}
                                onChange={(e) => handleTaskChange(index, 'value', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="Points"
                                type="number"
                                value={task.points}
                                onChange={(e) => handleTaskChange(index, 'points', e.target.value)}
                                sx={{ marginBottom: '20px' }}
                            />
                            <TextField
                                label="File URLs (Comma Separated)"
                                fullWidth
                                value={task.fileUrls.join(',')}
                                onChange={(e) => handleTaskChange(index, 'fileUrls', e.target.value.split(','))}
                                sx={{ marginBottom: '20px' }}
                            />
                        </div>
                    )}
                </div>
            ))}
            <Button
                variant="text"
                sx={{ marginBottom: '20px' }}
                onClick={handleAddTask}
            >
                + New Task
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#7BB92F', color: 'white', marginRight: '10px' }}
                onClick={handleConfirm}
            >
                Confirm
            </Button>
            <Button
                variant="contained"
                sx={{ backgroundColor: '#FF4D4F', color: 'white' }}
                onClick={onCancel}
            >
                Cancel
            </Button>
        </div>
    );
};

export default CreateQuestForm;